import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta'
import Container from '../components/Container';

const TermsAndConditions = () => {
  return (
    <>
      <Meta title='Terms And Conditions'/>
      <BreadCrumb title='Terms And Conditions'/>
      <Container class1='policy-wrapper'>
        <div className='Policy'>
          <div className='t-policy'>
        <h3>Terms and Conditions</h3>
          <br />
          <p>Welcome to Kupto</p>
          <br />
          <p>These terms and conditions outline the rules and regulations for the use of Kupto's Website, located at <a href="http://www.kupto.com">www.kupto.com</a></p>
          <br />
          <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Kupto if you do not agree to take all of the terms and conditions stated on this page.</p>
          <br />
          <p>1. <strong>Definitions</strong></p>
          <br />
          <ul>
            <li><strong>Company</strong>: Refers to Kupto.</li>
            <li><strong>Customer</strong>: Refers to the individual or entity that purchases goods from the Company.</li>
            <li><strong>Website</strong>: Refers to <a href="http://www.kupto.com">www.kupto.com</a>.</li>
          </ul>
          <br />
          <p>2. <strong>User Account</strong></p>
          <br />
          <ul>
            <li>To access certain features of our Website, you may be required to create an account.</li>
            <li>You are responsible for maintaining the confidentiality of your account and password.</li>
            <li>You agree to provide accurate and complete information when creating your account.</li>
          </ul>
          <br />
          <p>3. <strong>Order Placement</strong></p>
          <br />
          <ul>
            <li>By placing an order, you are offering to purchase a product subject to these terms and conditions.</li>
            <li>All orders are subject to availability and confirmation of the order price.</li>
            <li>The Company reserves the right to refuse any order for any reason.</li>
          </ul>
          <br />
          <p>4. <strong>Pricing and Payment</strong></p>
          <br />
          <ul>
            <li>All prices listed on the Website are in UGX (Ugandan Shillings) and include applicable taxes.</li>
            <li>Payment must be made at the time of purchase via accepted payment methods.</li>
            <li>The Company reserves the right to change prices at any time without prior notice.</li>
          </ul>
          <br />
          <p>5. <strong>Shipping and Delivery</strong></p>
          <br />
          <ul>
            <li>The Company will ship products to the address provided at checkout.</li>
            <li>Shipping times may vary based on location and product availability.</li>
            <li>The Company is not responsible for delays caused by third-party shipping providers.</li>
          </ul>
          <br />
          <p>6. <strong>Returns and Refunds</strong></p>
          <br />
          <ul>
            <li>Customers have 5 days to return products for a full refund or exchange.</li>
            <li>Products must be returned in their original condition and packaging.</li>
            <li>Refunds will be processed within 4 days of receiving the returned item.</li>
          </ul>
          <br />
          <p>7. <strong>Intellectual Property</strong></p>
          <br />
          <ul>
            <li>All content on the Website, including text, graphics, logos, and images, is the property of the Company.</li>
            <li>You may not use, reproduce, or distribute any content without the Company's express permission.</li>
          </ul>
          <br />
          <p>8. <strong>Limitation of Liability</strong></p>
          <br />
          <ul>
            <li>The Company is not liable for any indirect, incidental, or consequential damages arising from the use of the Website or products purchased from the Company.</li>
            <li>The Company's total liability to you for any damages shall not exceed the amount paid by you for the product.</li>
          </ul>
          <br />
          <p>9. <strong>Governing Law</strong></p>
          <br />
          <ul>
            <li>These terms and conditions are governed by and construed in accordance with the laws of Uganda.</li>
            <li>Any disputes arising out of these terms and conditions will be subject to the exclusive jurisdiction of the courts of Uganda.</li>
          </ul>
          <br />
          <p>10. <strong>Changes to Terms and Conditions</strong></p>
          <br />
          <ul>
            <li>The Company reserves the right to modify these terms and conditions at any time.</li>
            <li>Changes will be effective immediately upon posting on the Website.</li>
            <li>Your continued use of the Website after changes are posted constitutes your acceptance of the modified terms and conditions.</li>
          </ul>
          <br />
          <p><strong>Contact Us</strong></p>
          <br />
          <ul>
            <li>If you have any questions about these terms and conditions, please contact us at <a href="mailto:kupto@gmail.com">kupto@gmail.com</a>.</li>
          </ul>
          </div>










            
        </div>
      </Container>
     
    </>
  )
}

export default TermsAndConditions
