export const base_url = "http://localhost:5000/api/";

const getTokenFromLocalStorage = localStorage.getItem('customer')
  ? JSON.parse(localStorage.getItem('customer'))
  : null;

console.log('Retrieved token from local storage:', getTokenFromLocalStorage);

export const config = {
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer ${getTokenFromLocalStorage ? getTokenFromLocalStorage.token : ''}`,
  },
};

console.log('Request headers configuration:', config);

