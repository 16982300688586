import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BlogCard from '../components/BlogCard';
import mainbanner from '../images/banner/main banner-img.jpg';
import banner1 from '../images/banner/banner222.jpg';
import banner2 from '../images/banner/banner111.jpg';
import banner3 from '../images/banner/banner333.jpg';
import otherbanner1 from '../images/banner/other-banners-2.jpg';
import otherbanner2 from '../images/banner/other-banners-3.jpg';
import otherbanner3 from '../images/banner/other-banners-4.jpg';
import strip from '../images/banner/strip.jpg'
import Container from '../components/Container';
import {services} from '../utils/Data';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from 'react-rating-stars-component';
import { getAllProducts } from '../app/features/products/productSlice';
import { getAllPosters } from '../app/features/posters/posterSlice';
import { getAllPosts } from '../app/features/Posts/postSlice';
import { MdAdsClick } from "react-icons/md";
import { selectCategories } from '../app/features/category/categorySlice';
import { filterProducts } from '../app/features/products/productSlice';




const Home = () => {
  const dispatch = useDispatch();
  const productState = useSelector((state) => state?.product?.filteredProducts);
  const categories = useSelector(selectCategories);
  console.log(categories);
  
  const posterState = useSelector((state)=> state?.poster?.poster);

  const postState = useSelector((state)=> state?.post?.post);


  
  const navigate = useNavigate();

  
 

 
  
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 6,
      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };

  

  const responsive3 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const responsive4 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 320 },
      items: 2
    }, 
    smallest: {
      breakpoint: { max: 320, min: 0 },
      items: 1
    }
  };

  const responsive5 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 10,
      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 6
    },
    mobile: {
      breakpoint: { max: 464, min: 320 },
      items: 4
    }, 
    smallest: {
      breakpoint: { max: 320, min: 0 },
      items: 4
    }
  };
 
  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllPosters());
    dispatch(getAllPosts())
  }, [dispatch]); // Ensure dispatch is included as a dependency



  const getStarSize = () => {
    const width = window.innerWidth;
    if (width <= 360) {
      return 18; // Adjust size for small screens (e.g., mobile devices)
    } else if (width <= 768) {
      return 18; // Size for larger screens (e.g., tablets and desktops)
    } else {
      return 22; // Default size for very large screens
    }
  };

  // Effect to handle star size changes on window resize
  useEffect(() => {
    const handleResize = () => {
      setStarSize(getStarSize());
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [starSize, setStarSize] = React.useState(getStarSize());

  
  const handleClick = (categoryId) => {
    dispatch(filterProducts({ category: categoryId }));
    navigate(`/product?category=${encodeURIComponent(categoryId)}`);
  };

  


  return (
    <>
    <Container class1='home-banner-section'>
          <div className='mm-r'>

      <div className='main-banner'>
        <img src={mainbanner} alt='' className='w-100 slide'/>
        <div className='main-banner-content'>
          <h4>GET ALL YOUR</h4>
          <h5>PRINTING</h5>
          <h6><span>&</span> STATIONERY</h6>

          
          <p>on your smartphone</p>
          <div className='home-u d-flex gap-10'>
            <p>with just one</p>
            <p className='clin'>CLICK <MdAdsClick /></p>
            </div>
          <Link to='/print' className='print'>PRINT NOW</Link>
        </div>
      </div>

      <div className='other-banners d-flex flex-column gap-9'>
        <div className='top-banners d-flex gap-10'>
          <div>
            <img src={otherbanner1} alt='' className='w-100' />
          </div>
          <div>
            <img src={otherbanner2} alt='' className='w-100' />
          </div>
        </div>

        <div>
          <img src={otherbanner3} alt='' className='w-100' />
        </div>
      </div>

          </div>
    </Container>

    <Container class1='strip-des'>
       <img src={strip} alt='' className='w-100'/>
    </Container>

    <Container class1='banner-section-2'>
      <div className='container-fe'>
            <div className='services'>
              {
                services?.map((i,j) => {
                  return(
                    <div className='serv' key={j}>
                      {i.image}
                      <div>
                        <h6>{i.title}</h6>
                        <p>{i.tagline}</p>
                      </div>
                    </div>
                  )
                })
              }
             

            </div>
          
        
      </div>
    </Container>
  
    
    
    
    
    <Container class1='banner-section-3'>
      <h3 className='section-heading'>Featured Collection</h3>

      
        
          
      <Carousel showDots={true} itemClass='mb-20' className='blog' responsive={responsive}>
      {productState && productState.length > 0 ? (
        productState.map((item, index) => {
          if (item.tags === 'featured') {
            return (
              <div key={index} className='col-4'>
                <div onClick={() => navigate('/product/' + item?._id)} className='product-card'>
                  <div className='product-image'>
                    <img src={item?.images[0]?.url} alt='' className='prod-img' />
                  </div>
                  <div className='product-details'>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <ReactStars 
                      count={5}
                      size={starSize}
                      value={Number(item?.totalrating)}
                      edit={false}
                      activeColor='#ffdf00'
                    />
                  </div>
                    <p>{item?.brand}</p>
                    <h6 className='brand'>{item?.title}</h6>
                    <p className='price-style'>UGX <strong>{item?.price}</strong></p>
                    
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })
      ) : (
        <p>No Featured products found</p>
      )}
    </Carousel>
        

    </Container>

    <Container class1='banner-section-3'>
      <h3 className='section-heading'>Our Categories</h3>
      <Carousel 
      showDots={true} 
      itemClass='mb-20' 
      className='blog'
      responsive={responsive5}>
      {
              categories?.map((i,j) => {
                  return(
                    <div onClick={() => handleClick(i._id)} className='category' key={j}>
                      <div className='cat-image-d'>
                      <img src={i.images[0]?.url} alt='' className='w-100' />
                      </div>
                      <div >
                        
                        <p>{i.title}</p>
                      </div>
                    </div>
                  )
                })
              }
        
      </Carousel>

      </Container>

    <Container class1='other-bann d-flex flex-column '>
        <div className='top-banners d-flex gap-10'>
          <div>
            <img src={otherbanner1} alt='' className='w-100' />
          </div>
          <div>
            <img src={otherbanner2} alt='' className='w-100' />
          </div>
        </div>

        <div className='lower-main mt-8'>
          <img src={otherbanner3} alt='' className='w-100' />
        </div>
      </Container>

    <Container class1='banner-section-3 bord-sub'>
      <div className='sub-flex'>
        <div className='sub-banner'>
          <img src={banner1} alt='' className='w-100' />
          <div className='sub-details'>
            <p>KUPTO APP</p>
            <div className='bord-b'></div>
            <h1>HIGH</h1>
            <h2 className='quali-s'>QUALITY</h2>
            <h1>WEAR</h1>
            <p>From ugx 30,000 or 40,000</p>
          </div>
        </div>
        <div className='sub-banner'>
        <img src={banner2} alt='' className='w-100' />
        <div className='sub-details'>
            <p className='w-bl'>KUPTO APP</p>
            <div className='bord-b b-new'></div>
            <h1 className='blue-f'>HIGH</h1>
            <h2 className='quali-s new-qua'>QUALITY</h2>
            <h1 className='blue-f'>WEAR</h1>
            <p className='w-bl'>From ugx 30,000 or 40,000</p>
          </div>
        </div>
        <div className='sub-banner'>
        <img src={banner3} alt='' className='w-100' />
        <div className='sub-details'>
            <p>KUPTO APP</p>
            <div className='bord-b'></div>
            <h1>HIGH</h1>
            <h2 className='quali-s'>QUALITY</h2>
            <h1>WEAR</h1>
            <p>From ugx 30,000 or 40,000</p>
          </div>

        </div>
      </div>
    </Container>

    <Container class1="special-wrapper">
      <h3 className="section-heading">Newest Products</h3>
      <Carousel 
          showDots={true} 
          itemClass='mb-20' 
          className='blog' 
          responsive={responsive}>
            {productState && productState.length > 0 ? (
          productState.map((item, index) => {
            
            if (item.tags === 'popular') {
              return (
                <div
          key={index}
          className={'col-4'}
        >
          <div onClick={()=> navigate('/product/'+ item?._id)} className='product-card'>
            <div className='product-image'>
              <img  src={item?.images[0]?.url} alt='' className='prod-img' />
            </div>
            <div className='product-details'>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <ReactStars 
                count={5}
                size={starSize}
                value={Number(item?.totalrating)}
                edit={false}
                activeColor='#ffdf00'
              />
            </div>
              <p>{item?.brand}</p>
              <h6 className='brand'>{item?.title}</h6>
              
            
              <p className='price-style'>UGX <strong>{item?.price}</strong></p>

              {/*<div className='wish'>
                <button onClick={()=>addToWish(item?._id)} className='border-0 bg-transparent'>
                  <img src={whish} alt='' />
                </button>
              </div>*/}
              
             
            </div>
          </div>
        </div>
              );
            }
            return null;
          })
        ) : (
          <p>No Popular products found</p>
        )}
         
          
        </Carousel>
    </Container>

    <Container class1='descrip-section'>
      <div className='des-cont'>

      {postState && postState.length > 0 ? (
          postState.map((item, index) => (
                <div className='desss' key={index}>
                  <div className='des-doc'>
                    <div className='position-absolute'>
                    <h6>KUPTO APP</h6>
                    <h4>{item?.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                    
                    </div>
                    <img src={item?.images[0]?.url} alt='' className='descrip-photo' />
                  </div>
                </div>
          ))
        ) : (
          <p>No posts found</p>
        )}
   
        </div>
    </Container>

    

    <Container class1='banner-section-3 our-none'>
      <h3 className='section-heading'>Our Popular Products</h3>
        
        <Carousel 
          showDots={true} 
          itemClass='mb-20' 
          className='blog' 
          responsive={responsive}>
            {productState && productState.length > 0 ? (
          productState.map((item, index) => {
            
            if (item.tags === 'popular') {
              return (
                <div
          key={index}
          className={'col-4'}
        >
          <div onClick={()=> navigate('/product/'+ item?._id)} className='product-card'>
            <div className='product-image'>
              <img  src={item?.images[0]?.url} alt='' className='prod-img' />
            </div>
            <div className='product-details'>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <ReactStars 
                count={5}
                size={starSize}
                value={Number(item?.totalrating)}
                edit={false}
                activeColor='#ffdf00'
              />
            </div>
              <p>{item?.brand}</p>
              <h6 className='brand'>{item?.title}</h6>
              
            
              <p className='price-style'>UGX <strong>{item?.price}</strong></p>

              {/*<div className='wish'>
                <button onClick={()=>addToWish(item?._id)} className='border-0 bg-transparent'>
                  <img src={whish} alt='' />
                </button>
              </div>*/}
              
             {/* <div className='add-to-cart'>
                <button className='add-c bg-transparent'>
                <FaCartArrowDown className='fs-3'/>
                </button>
              </div>*/}
            </div>
          </div>
        </div>
              );
            }
            return null;
          })
        ) : (
          <p>No Popular products found</p>
        )}
         
          
        </Carousel>
        
          
          
       
    </Container>

    <Container class1='blog-wrapper dis-post'>
      <h3 className='section-heading'>Our Latest Posts</h3>
      <Carousel 
      showDots={true} 
      itemClass='mb-20' 
      className='blog'
      responsive={responsive3}> 
        {Array.isArray(posterState) && posterState.length > 0 ? (
      posterState.map((item) => (
        <BlogCard
          key={item?._id || item?.title || item?.date} // Ensure a unique key if _id is unavailable
          id={item?._id}
          description={item?.description || 'No description available'}
          title={item?.title || 'Untitled'}
          images={item?.images} // Ensure that the BlogCard component can handle the images array
          date={item?.date || 'No date available'}
        />
      ))
    ) : (
      <p>No posts available.</p>
    )}
          
        </Carousel>
    </Container>

    <Container class1='banner-section-3 our-pop'>
      <h3 className='section-heading'>Our Popular Products</h3>
        
        <Carousel 
          showDots={true} 
          itemClass='mb-20' 
          className='blog' 
          responsive={responsive}>
            {productState && productState.length > 0 ? (
          productState.map((item, index) => {
            
            if (item.tags === 'popular') {
              return (
                <div
          key={index}
          className={'col-4'}
        >
          <div onClick={()=> navigate('/product/'+ item?._id)} className='product-card'>
            <div className='product-image'>
              <img  src={item?.images[0]?.url} alt='' className='prod-img' />
            </div>
            <div className='product-details'>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <ReactStars 
                count={5}
                size={starSize}
                value={Number(item?.totalrating)}
                edit={false}
                activeColor='#ffdf00'
              />
            </div>
              <p>{item?.brand}</p>
              <h6 className='brand'>{item?.title}</h6>
              
            
              <p className='price-style'>UGX <strong>{item?.price}</strong></p>

              {/*<div className='wish'>
                <button onClick={()=>addToWish(item?._id)} className='border-0 bg-transparent'>
                  <img src={whish} alt='' />
                </button>
              </div>*/}
              
             
            </div>
          </div>
        </div>
              );
            }
            return null;
          })
        ) : (
          <p>No Popular products found</p>
        )}
         
          
        </Carousel>

       
        
          
          
       
    </Container>


    <Container class1='descrip-section dis-no'>
    <Carousel 
          showDots={true} 
          itemClass='mb-20' 
          className='des-cont' 
          responsive={responsive4}> 

      {postState && postState.length > 0 ? (
          postState.map((item, index) => (
                <div className='desss' key={index}>
                  <div className='des-doc'>
                    <div className='position-absolute'>
                    <h6>KUPTO APP</h6>
                    <h4>{item?.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                    
                    </div>
                    <img src={item?.images[0]?.url} alt='' className='descrip-photo' />
                  </div>
                </div>
          ))
        ) : (
          <p>No posts found</p>
        )}
   
        </Carousel>
    </Container>

    <Container class1='blog-wrapper big-post'>
      <h3 className='section-heading'>Our Latest Posts</h3>
      <Carousel 
      showDots={true} 
      itemClass='mb-20' 
      className='blog'
      responsive={responsive3}> 
        {Array.isArray(posterState) && posterState.length > 0 ? (
      posterState.map((item) => (
        <BlogCard
          key={item?._id || item?.title || item?.date} // Ensure a unique key if _id is unavailable
          id={item?._id}
          description={item?.description || 'No description available'}
          title={item?.title || 'Untitled'}
          images={item?.images} // Ensure that the BlogCard component can handle the images array
          date={item?.date || 'No date available'}
        />
      ))
    ) : (
      <p>No posts available.</p>
    )}
          
        </Carousel>
    </Container>

    <Container class1='banner-section-3'>
      <h3 className='section-heading'>Product Printing</h3>

      
        
          
      <Carousel showDots={true} itemClass='mb-20' className='blog' responsive={responsive}>
      {productState && productState.length > 0 ? (
        productState.map((item, index) => {
          if (item.tags === 'featured') {
            return (
              <div key={index} className='col-4'>
                <div onClick={() => navigate('/product/' + item?._id)} className='product-card'>
                  <div className='product-image'>
                    <img src={item?.images[0]?.url} alt='' className='prod-img' />
                  </div>
                  <div className='product-details'>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <ReactStars 
                      count={5}
                      size={starSize}
                      value={Number(item?.totalrating)}
                      edit={false}
                      activeColor='#ffdf00'
                    />
                  </div>
                    <p>{item?.brand}</p>
                    <h6 className='brand'>{item?.title}</h6>
                    <p className='price-style'>UGX <strong>{item?.price}</strong></p>
                    
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })
      ) : (
        <p>No Featured products found</p>
      )}
    </Carousel>
        

    </Container>




   



    </>
  )
}

export default Home;


/*
{
        productState && 
        productState.map((item, index)=>{
          if (items.tag === 'featured') {
            return (
              <div key={index} className='col-3'>
                <div
                className='product-card position-relative'
                >
                <div className='wishlist-icon position-absolute'>
                  <button className='border-0 bg-transparent'>
                    <img 
                      onClick={()=> addToWish(item?._id)}
                      src={wish}
                      alt='wishlist'
                    />
                  </button>
                </div>
                <div className='product-image'>
                  <img 
                    src={item?.images[0].url}
                    className='img-fluid mx-auto'
                    alt='product image'
                    width={160}
                  />
                </div>
                  <div className='product-details'>
                    <ReactStars 
                      count={5}
                      size={24}
                      value={item?.totalrating.toString()}
                      edit={false}
                      activeColor='#ffd700'
                    />
                    <h6 className='brand'>{item?.brand}</h6>
                    <h5 className='product-title'>{item?.title}</h5>
                    <p className='price'>UGX {item?.price}</p>
                  </div>
                </div>
              </div>
            )
          }
        })
      }
      */