import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NavbarMobile from '../components/NavbarMobile';
import CartAddBar from '../components/CartAddBar'; // Import CartAddBar
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const shouldHideHeader = (pathname) => {
  const noHeaderPaths = ['/signup', '/login', '/forgot-password'];
  return noHeaderPaths.includes(pathname);
};

const shouldHideFooter = (pathname) => {
  const noFooterPaths = ['/checkout', /^\/product\/[^/]+$/];
  return noFooterPaths.some((path) =>
    typeof path === 'string' ? pathname === path : path.test(pathname)
  );
};

const shouldHideNavbarMobile = (pathname) => {
  const productPathPattern = /^\/product\/[^/]+$/; // Matches /product/anything
  return productPathPattern.test(pathname);
};

const Layout = () => {
  const location = useLocation();
  const hideHeader = shouldHideHeader(location.pathname);
  const hideFooter = shouldHideFooter(location.pathname);
  const hideNavbarMobile = shouldHideNavbarMobile(location.pathname);
  const isSingleProductPage = /^\/product\/[^/]+$/.test(location.pathname); // Check if it's the single product page

  return (
    <>
      {!hideHeader && <Header />}
      <Outlet />
      {isSingleProductPage && <CartAddBar />} {/* Render CartAddBar on single product page */}
      {!hideFooter && <Footer />}
      {!hideNavbarMobile && <NavbarMobile />}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Layout;