import { Helmet } from 'react-helmet';
import React from 'react';

const Meta = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{props.title}</title>
    </Helmet>
  );
}

export default Meta;