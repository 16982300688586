/*
import React from 'react';
import { Navigate } from 'react-router-dom';

export const OpenRoutes = ({ children }) => {
  const getTokenFromLocalStorage = JSON.parse(localStorage.getItem('customer'));
  return getTokenFromLocalStorage?.token ? <Navigate to="/" replace /> : children;
}*/

import { Navigate } from 'react-router-dom';

export const OpenRoutes = ({ children }) => {
  const getTokenFromLocalStorage = JSON.parse(localStorage.getItem('customer'));
  return getTokenFromLocalStorage?.token ? <Navigate to="/" replace /> : children;
}
  

