import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { printService } from "./printService";

export const createAPrintOrder = createAsyncThunk(
  'print/create-printOrder',
  async (orderData, thunkAPI) => {
    try {
      const response = await printService.createOrder(orderData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Failed to create print order');
    }
  }
);

export const getAPrintOrder = createAsyncThunk(
  'print/get-printOrder',
  async (id, thunkAPI) => {
    try {
      const response = await printService.getOrder(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch print order');
    }
  }
);

export const getAllPrintOrders = createAsyncThunk(
  'print/get-printOrders',
  async (thunkAPI) => {
    try {
      const response = await printService.getOrders();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch print orders');
    }
  }
);

const initialState = {
  printOrder: null,
  printOrders: [], // To store a list of orders
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    reset: (state) => {
      state.printOrder = null;
      state.printOrders = []; // Reset the list of orders
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAPrintOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAPrintOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.printOrder = action.payload;
        toast.success('Print order created successfully!');
      })
      .addCase(createAPrintOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(state.message);
      })
      .addCase(getAPrintOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAPrintOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.printOrder = action.payload;
      })
      .addCase(getAPrintOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(state.message);
      })
      .addCase(getAllPrintOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPrintOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.printOrders = action.payload; // Update the list of orders
      })
      .addCase(getAllPrintOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(state.message);
      });
  },
});

export const { reset } = printSlice.actions;
export default printSlice.reducer;