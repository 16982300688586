import React, { useEffect } from 'react'
import Container from '../components/Container'
import BreadCrumb from '../components/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { getOrders } from '../app/features/user/userSlice'

const Orders = () => {
  const dispatch = useDispatch();
  const orderState = useSelector(state => state?.auth?.getOrderedProduct?.orders);
  console.log(orderState);

  useEffect(()=>{
    dispatch(getOrders());
  },[dispatch]);
  return (
    <>
      <BreadCrumb title='My Orders'/>
      <Container class1='section-11'>
    <div className='col-122'>
      <div className='row mb-10'>
        <div className='col-1'><strong>ORDER ID</strong></div>
        <div className='col-1'><strong>TOTAL AMOUNT</strong></div>
        <div className='col-1'><strong>TOTAL AFTER DISCOUNT</strong></div>
        <div className='col-1'><strong>STATUS</strong></div>
    </div>

    

    {
      orderState && orderState?.map((item, index)=>{
        return(
          <div key={index}>
            <div className='row pad color'>
              <div className='col-1'><p>{item?._id}</p></div>
              <div className='col-1'><p>UGX {item?.totalPrice}</p></div>
              <div className='col-1'><p>UGX {item?.totalPriceAfterDiscount}</p></div>
              <div className='col-1'><p>{item?.orderStatus}</p></div>
          </div>

          <div className='row bg-secondary pad'>
            <div className='col-1'><p>Product Name</p></div>
            <div className='col-1'><p>Quantity</p></div>
            <div className='col-1'><p>Price</p></div>
            <div className='col-1'><p>Color</p></div>
            <div className='col-1'><p>Size</p></div>

        </div>
          {
            item?.orderItems?.map((i, index)=>{
              return (
                <div className='row bg-secondary pad mb-10'>
                  <div className='col-1'><p>{i?.product?.title}</p></div>
                  <div className='col-1'><p>{i?.quantity}</p></div>
                  <div className='col-1'><p>{i?.price}</p></div>
                  <div className='col-1'><ul className="colors">
                      <li style={{ backgroundColor: i?.color?.title }}></li>
                    </ul></div>
                    <div className='col-1'><p>{i?.size}</p></div>
              </div>
              )
            })
          }
        
        </div>
        )
      })
    }

    
    
  </div>
</Container>
    </>
  )
}

export default Orders
