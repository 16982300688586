import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
//import { useSelector } from 'react-redux';
import { CiShoppingCart } from "react-icons/ci";
import { FaRegHeart } from "react-icons/fa";



const CartAddBar = () => {
 // const authState = useSelector(state => state?.auth);
  const location = useLocation();

  // Check if the current path matches the single product page pattern
  const isSingleProductPage = matchPath({ path: '/product/:id', end: true }, location.pathname);

  if (!isSingleProductPage) {
    return null; // Do not render the component if not on the single product page
  }

  return (
    <div className="navbar-mobile bb">
      <Link to= '/cart' className='cart-bar1'>
        <FaRegHeart className='fs-cart2'/>
        <p>Add to wishlist</p>
      </Link>

      

      <Link to= '/cart' className='cart-bar'>
        <CiShoppingCart className='fs-cart1'/>
        <p>Add to cart</p>
      </Link>
    </div>
  );
};

export default CartAddBar;