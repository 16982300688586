import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta'
import Container from '../components/Container';

const RefundPolicy = () => {
  return (
    <>
      <Meta title='Refund Policy'/>
      <BreadCrumb title='Refund Policy'/>
      <Container class1='policy-wrapper'>
        <div className='Policy'>
        <h1 className="mb-4">Refund Policy</h1>
      
      <section className="mb-5">
        <h2>Overview</h2>
        <p>
          At Kupto, we strive to provide the best shopping experience for our customers. If you are not completely satisfied with your purchase, we're here to help. Please review our refund policy below for details on returns, refunds, and exchanges.
        </p>
      </section>

      <section className="mb-5">
        <h2>Returns</h2>
        <p>
          Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately, we can’t offer you a refund or exchange.
        </p>
        <p>
          To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
        </p>
        <p>
          Several types of goods are exempt from being returned:
        </p>
        <ul>
          <li>Perishable goods such as food, flowers, newspapers, or magazines</li>
          <li>Intimate or sanitary goods</li>
          <li>Hazardous materials or flammable liquids or gases</li>
          <li>Gift cards</li>
          <li>Downloadable software products</li>
          <li>Some health and personal care items</li>
        </ul>
        <p>
          To complete your return, we require a receipt or proof of purchase. Please do not send your purchase back to the manufacturer.
        </p>
      </section>
          
        </div>
      </Container>
    
    </>
  )
}

export default RefundPolicy
