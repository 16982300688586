import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { authService } from "./userService";
import { toast } from "react-toastify";

export const registerUser = createAsyncThunk('auth/register', async (userData, thunkAPI)=>{
  try {
    return await authService.register(userData)
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const loginUser = createAsyncThunk('auth/login', async (userData, thunkAPI)=>{
  try {
    return await authService.login(userData)
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getUserProductWishlist = createAsyncThunk('user/wishlist', async (thunkAPI)=>{
  try {
    return await authService.getUserWishlist();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addProdToCart = createAsyncThunk('user/cart/add', async (cartData, thunkAPI) => {
  try {
    return await authService.addToCart(cartData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getUserCart = createAsyncThunk('user/cart/get', async (thunkAPI)=>{
  try {
    return await authService.getCart();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteUserCart = createAsyncThunk('user/cart/delete', async (thunkAPI)=>{
  try {
    return await authService.emptyCart();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getOrders = createAsyncThunk('user/order/get', async (thunkAPI)=>{
  try {
    return await authService.getUserOrders();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteCartProduct = createAsyncThunk('user/cart/product/delete', async (id, thunkAPI)=>{
  try {
    return await authService.removeProductFromCart(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateCartProduct = createAsyncThunk('user/cart/product/update', async (cartDetail, thunkAPI)=>{
  try {
    return await authService.updateProductFromCart(cartDetail);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateProfile = createAsyncThunk('user/profile/update', async (data, thunkAPI)=>{
  try {
    return await authService.updateUser(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const forgotPasswordTok = createAsyncThunk('user/password/token', async (data, thunkAPI)=>{
  try {
    return await authService.forgotPassToken(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const resetPassword = createAsyncThunk('user/password/reset', async (data, thunkAPI)=>{
  try {
    return await authService.resetPass(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createAnOrder = createAsyncThunk('user/cart/create-order', async (data, thunkAPI) => {
  try {
    return await authService.createOrder(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
    
  }
});

export const resetState = createAction('Reset_all');

const getCustomerfromLocalStorage = localStorage.getItem('customer')? JSON.parse(localStorage.getItem('customer')) : null;

const initialState = {
  user: getCustomerfromLocalStorage,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder)=> {
    builder.addCase(registerUser.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(registerUser.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.createdUser = action.payload;
      if(state.isSuccess === true) {
        toast.info('User Created Successfully')
      }
    })
    .addCase(registerUser.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      if(state.isError === true) {
        toast.info(action.payload.response.data.message)
      }
    })
    .addCase(loginUser.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(loginUser.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.user = action.payload;
      if(state.isSuccess === true) {
        localStorage.setItem('token', action.payload.token);
        toast.info('User Logged in Successfully');
      }
    })
    .addCase(loginUser.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      if(state.isError === true) {
        toast.info(action.payload.response.data.message)
      }
    })
    .addCase(getUserProductWishlist.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(getUserProductWishlist.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.wishlist = action.payload;
      
    })
    .addCase(getUserProductWishlist.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      
    })
    .addCase(addProdToCart.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(addProdToCart.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.cartProduct = action.payload;
      if(state.isSuccess) {
        toast.success('Product Added to Cart')
      }
      
    })
    .addCase(addProdToCart.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      
    })
    .addCase(getUserCart.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(getUserCart.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.cartProduct = action.payload;
      
      

     
    })
    .addCase(getUserCart.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      
    })
    .addCase(deleteCartProduct.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(deleteCartProduct.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.deletedCartProduct = action.payload;
      if (state.isSuccess) {
        toast.success('Product Deleted From Cart Successfully!')
      }
     
    })
    .addCase(deleteCartProduct.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      if (state.isSuccess === false) {
        toast.error('Something went wrong!')
      }
    })
    .addCase(updateCartProduct.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(updateCartProduct.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.updatedCartProduct = action.payload;
      if (state.isSuccess) {
        toast.success('Product updated From Cart Successfully!')
      }
     
    })
    .addCase(updateCartProduct.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      if (state.isSuccess === false) {
        toast.error('Something went wrong!')
      }
    })
    .addCase(getOrders.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(getOrders.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.getOrderedProduct = action.payload;
     
    })
    .addCase(getOrders.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      
    })
    .addCase(updateProfile.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(updateProfile.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.updatedUser = action.payload;
      if (state.isSuccess === true) {
        let currentUserData = JSON.parse(localStorage.getItem('customer'));
        let newUserData =  {
          _id: currentUserData?._id,
          token: currentUserData?.token,
          firstname: action?.payload?.firstname,
          lastname: action?.payload?.lastname,
          email: action?.payload?.email,
          mobile: action?.payload?.mobile,
        }
        localStorage.setItem('customer', JSON.stringify(newUserData));
        state.user = newUserData;
        toast.success('Profile updated Successfully!');
      }
     
    })
    .addCase(updateProfile.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      if (state.isSuccess === false) {
        toast.error('Something went wrong!')
      }
    })
    .addCase(forgotPasswordTok.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(forgotPasswordTok.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.token = action.payload;
      if (state.isSuccess) {
        toast.success('Forgot Password Email Sent Successfully!')
      }
     
    })
    .addCase(forgotPasswordTok.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      if (state.isSuccess === false) {
        toast.error('Something went wrong!')
      }
    })
    .addCase(resetPassword.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(resetPassword.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.pass = action.payload;
      if (state.isSuccess) {
        toast.success('Password Updated Successfully!')
      }
     
    })
    .addCase(resetPassword.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      if (state.isSuccess === false) {
        toast.error('Something went wrong!')
      }
    })
    .addCase(deleteUserCart.pending, (state)=>{
      state.isLoading = true;
    })
    .addCase(deleteUserCart.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.deletedCart = action.payload;
      
     
    })
    .addCase(deleteUserCart.rejected, (state, action)=>{
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      
    })
    .addCase(createAnOrder.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(createAnOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.createdOrder = action.payload;
      if (state.isSuccess) {
        toast.success('Order Created Successfully!');
      }
    })
    .addCase(createAnOrder.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.error;
      if (state.isSuccess === false) {
        toast.error('Something went wrong!');
      }
    })
    .addCase(resetState, () => initialState);
  }
})

export default authSlice.reducer;


/*
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { authService } from "./userService";
import { toast } from "react-toastify";

export const registerUser = createAsyncThunk('auth/register', async (userData, thunkAPI) => {
  try {
    return await authService.register(userData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const loginUser = createAsyncThunk('auth/login', async (userData, thunkAPI) => {
  try {
    const response = await authService.login(userData);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getUserProductWishlist = createAsyncThunk('user/wishlist', async (_, thunkAPI) => {
  try {
    return await authService.getUserWishlist();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addProdToCart = createAsyncThunk('user/cart/add', async (cartData, thunkAPI) => {
  try {
    return await authService.addToCart(cartData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getUserCart = createAsyncThunk('user/cart/get', async (thunkAPI) => {
  try {
    const cart = await authService.getCart();
    console.log('Cart fetched:', cart); // Debugging line
    return cart;
  } catch (error) {
    console.error('Error fetching cart:', error); // Debugging line
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const deleteUserCart = createAsyncThunk('user/cart/delete', async (_, thunkAPI) => {
  try {
    return await authService.emptyCart();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getOrders = createAsyncThunk('user/order/get', async (_, thunkAPI) => {
  try {
    return await authService.getUserOrders();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteCartProduct = createAsyncThunk('user/cart/product/delete', async (id, thunkAPI) => {
  try {
    return await authService.removeProductFromCart(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateCartProduct = createAsyncThunk('user/cart/product/update', async (cartDetail, thunkAPI) => {
  try {
    return await authService.updateProductFromCart(cartDetail);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateProfile = createAsyncThunk('user/profile/update', async (data, thunkAPI) => {
  try {
    return await authService.updateUser(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const forgotPasswordTok = createAsyncThunk('user/password/token', async (data, thunkAPI) => {
  try {
    return await authService.forgotPassToken(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const resetPassword = createAsyncThunk('user/password/reset', async (data, thunkAPI) => {
  try {
    return await authService.resetPass(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createAnOrder = createAsyncThunk('user/cart/create-order', async (data, thunkAPI) => {
  try {
    return await authService.createOrder(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const logoutUser = createAction('auth/logout');

export const resetState = createAction('Reset_all');

const getCustomerfromLocalStorage = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')) : null;

const initialState = {
  user: getCustomerfromLocalStorage,
  isAuthenticated: !!getCustomerfromLocalStorage,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',

};

const handlePending = (state) => {
  state.isLoading = true;
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.isSuccess = false;
  state.message = action.error.message || 'Something went wrong!';
  console.error('Error details:', action.error); // Debugging line
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, handlePending)
    .addCase(registerUser.fulfilled, (state, action) => {
      handleFulfilled(state, action);
      state.user = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('customer', JSON.stringify(action.payload));
      toast.info('User Registered Successfully');
    })
      .addCase(registerUser.rejected, handleRejected)

      .addCase(loginUser.pending, handlePending)
      .addCase(loginUser.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.user = action.payload;
        state.isAuthenticated = true;
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('customer', JSON.stringify(action.payload));
        toast.info('User Logged In Successfully');
        
      })
      .addCase(loginUser.rejected, handleRejected)

      .addCase(getUserProductWishlist.pending, handlePending)
      .addCase(getUserProductWishlist.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.wishlist = action.payload;
      })
      .addCase(getUserProductWishlist.rejected, handleRejected)

      .addCase(addProdToCart.pending, handlePending)
      .addCase(addProdToCart.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.cartProduct = action.payload;
        toast.success('Product Added to Cart');
      })
      .addCase(addProdToCart.rejected, handleRejected)

      .addCase(getUserCart.pending, handlePending)
      .addCase(getUserCart.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.cartProduct = action.payload;
      })
      .addCase(getUserCart.rejected, handleRejected)

      .addCase(deleteCartProduct.pending, handlePending)
      .addCase(deleteCartProduct.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.deletedCartProduct = action.payload;
        toast.success('Product Deleted From Cart Successfully!');
      })
      .addCase(deleteCartProduct.rejected, handleRejected)

      .addCase(updateCartProduct.pending, handlePending)
      .addCase(updateCartProduct.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updatedCartProduct = action.payload;
        toast.success('Product Updated In Cart Successfully!');
      })
      .addCase(updateCartProduct.rejected, handleRejected)

      .addCase(getOrders.pending, handlePending)
      .addCase(getOrders.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.getOrderedProduct = action.payload;
      })
      .addCase(getOrders.rejected, handleRejected)

      .addCase(updateProfile.pending, handlePending)
      .addCase(updateProfile.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        let currentUserData = JSON.parse(localStorage.getItem('customer'));
        let newUserData = {
          _id: currentUserData?._id,
          token: currentUserData?.token,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          email: action.payload.email,
          mobile: action.payload.mobile,
        };
        localStorage.setItem('customer', JSON.stringify(newUserData));
        state.user = newUserData;
        toast.success('Profile Updated Successfully!');
      })
      .addCase(updateProfile.rejected, handleRejected)

      .addCase(forgotPasswordTok.pending, handlePending)
      .addCase(forgotPasswordTok.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.token = action.payload;
        toast.success('Forgot Password Email Sent Successfully!');
      })
      .addCase(forgotPasswordTok.rejected, handleRejected)

      .addCase(resetPassword.pending, handlePending)
      .addCase(resetPassword.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.pass = action.payload;
        toast.success('Password Updated Successfully!');
      })
      .addCase(resetPassword.rejected, handleRejected)

      .addCase(deleteUserCart.pending, handlePending)
      .addCase(deleteUserCart.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.deletedCart = action.payload;
      })
      .addCase(deleteUserCart.rejected, handleRejected)

      .addCase(createAnOrder.pending, handlePending)
      .addCase(createAnOrder.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.createdOrder = action.payload;
        toast.success('Order Created Successfully!');
      })
      .addCase(createAnOrder.rejected, handleRejected)

      .addCase(logoutUser, (state) => {
        state.user = null;
        state.isAuthenticated = false;
        localStorage.removeItem('token');
        localStorage.removeItem('customer');
        state.cartProduct = [];
        toast.info('User Logged Out Successfully');
      })
      .addCase(resetState, () => initialState); // Ensure resetState is at the end
  },
});

export default authSlice.reducer; */