import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta'
import Container from '../components/Container';

const PrivacyPolicy = () => {
  return (
    <>
    <Meta title='Privacy Policy'/>
    <BreadCrumb title='Privacy Policy'/>
      <Container class1='policy-wrapper'>
        <div className='Policy'>
        <h1>Kupto Privacy Notice</h1>
        <h4 className='mt-10'>1. About the Notice</h4>
        <p>This Privacy Notice provides information on how Kupto collects and processes your personal data when you visit our website or mobile applications. It sets out what we do with your personal data and how we keep it secure and explains the rights that you have in relation to your personal data.</p>

        <h4 className='mt-10'>2. Types of Information Collected</h4>
        <p>This may include:
          Contact information (name, email address, phone number, shipping address)
          Payment information (credit card details, billing address)
          Account credentials (username, password)
          Device information (IP address, browser type, operating system)
          Behavioral data (purchase history, browsing activity)
        </p>

        <h4 className='mt-10'>3. Purpose of Information Collection</h4>
        <p>This may include:
          <li>Processing orders and payments</li> 
          <li>Providing customer support</li> 
          <li>Personalizing user experience</li>
          <li>Sending promotional offers and marketing communications</li>
          <li>Analyzing website performance and user behavior</li>
          <li>Legal Basis for Processing: Clarify the legal basis for processing personal data, such as consent, contract
            performance, legitimate interests, or legal obligations.</li>
          </p>

          <h4 className='mt-10'>4. Data Security Measures</h4>
          <p>We have put in place security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.

            In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
            
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
          </p>

          <h4 className='mt-10'>5. User Rights Regarding Personal Data
          </h4>
          <p className='mt-10'><li><strong>Right to Access</strong>: Users have the right to request access to the personal data that you hold about them. This includes information about what data is being processed, the purposes of processing, and any third parties with whom the data is shared.</li>
            </p>
            <p className='mt-10'><li><strong>Right to Rectification</strong>:  Users have the right to request the correction of inaccurate or incomplete personal data. They can update their information directly through their account settings or by contacting customer support.</li>
            </p>
            <p className='mt-10'><li><strong>Right to Erasure (Right to Be Forgotten)</strong>:  Users have the right to request the deletion of their personal data under certain circumstances. This includes situations where the data is no longer necessary for the purposes for which it was collected or if the user withdraws consent for processing.</li>
            </p>
            <p className='mt-10'><li><strong>Right to Restrict Processing</strong>:  Users can request the restriction of processing of their personal data under certain circumstances. This means that while the processing is restricted, their data can only be stored and not further processed.</li>
            </p>
            <p className='mt-10'><li><strong>Right to Data Portability</strong>:  Users have the right to receive a copy of their personal data in a structured, commonly used, and machine-readable format. They can also request that you transmit this data to another data controller, where technically feasible.</li>
            </p>
            <p className='mt-10'><li><strong>Right to Object</strong>:  Users can object to the processing of their personal data in certain situations, such as direct marketing or processing based on legitimate interests. Upon receiving an objection, you must stop processing the data unless you have legitimate grounds for processing that override the user's interests, rights, and freedoms.</li>
            </p>
            <p className='mt-10'><li><strong>Right to Withdraw Consent</strong>:   If you rely on consent as the legal basis for processing personal data, users have the right to withdraw their consent at any time. This should be as easy as giving consent in the first place, and users should be informed of this right.</li>
            </p>
            <p className='mt-10'><li><strong>Right to Lodge a Complaint</strong>:  Users have the right to lodge a complaint with a supervisory authority if they believe that their rights regarding their personal data have been violated. You should provide users with information on how to contact the relevant data protection authority.</li>
            </p>


            <h4 className='mt-10'>6. Cookies and Tracking Technologies</h4>
            <p className='mt-10'><li><strong>Personalization</strong>: We use cookies to personalize your shopping experience, showing you relevant products and recommendations based on your browsing history and preferences.</li>
              </p>
              <p className='mt-10'><li><strong>Analytics</strong>:  We employ cookies to collect information about how you interact with our website, including the pages you visit, the links you click, and the duration of your visits. This data helps us analyze website traffic and improve our services.</li>
              </p>
              <p className='mt-10'><li><strong>Advertising</strong>: Third-party cookies may be used to deliver personalized advertisements to you based on your interests and browsing behavior. These cookies track your online activity across different websites and tailor ads accordingly.</li>
              </p>
              <p className='mt-10'><li><strong>Security</strong>: Cookies also play a role in ensuring the security of your account and transactions. For example, they help us detect and prevent fraudulent activities.</li>
              </p>
        </div>
      </Container>
    
    </>
  )
}

export default PrivacyPolicy