import axios from "axios";
import { base_url } from "../../../utils/axiosConfig";

const uploadFile = async (data) => {
  try {
    const response = await axios.post(`${base_url}uploadFile/`, data);
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error.response || error.message);
    throw error;
  }
};

const deleteFile = async (id) => {
  try {
    const response = await axios.delete(`${base_url}uploadFile/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting file:', error.response || error.message);
    throw error;
  }
};

const uploadService = {
  uploadFile,
  deleteFile,
};

export default uploadService;