import React, { useEffect, useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCartProduct, getUserCart, updateCartProduct } from '../app/features/user/userSlice';

const Cart = () => {
  const dispatch = useDispatch();
  const [productUpdateDetail, setProductUpdateDetail] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const userCartState = useSelector(state => state?.auth?.cartProduct);
  console.log(userCartState);

  useEffect(() => {
    dispatch(getUserCart());
  }, [dispatch]);

  useEffect(() => {
    if (productUpdateDetail) {
      dispatch(updateCartProduct({
        cartItemId: productUpdateDetail.cartItemId,
        quantity: productUpdateDetail.quantity
      }));
      setTimeout(() => {
        dispatch(getUserCart());
      }, 200);
    }
  }, [productUpdateDetail, dispatch]);

  const deleteACartProduct = (id) => {
    dispatch(deleteCartProduct(id));
    setTimeout(() => {
      dispatch(getUserCart());
    }, 200);
  };

  const incrementQuantity = (id, currentQuantity) => {
    setProductUpdateDetail({
      cartItemId: id,
      quantity: currentQuantity + 1
    });
  };

  const decrementQuantity = (id, currentQuantity) => {
    if (currentQuantity > 1) {
      setProductUpdateDetail({
        cartItemId: id,
        quantity: currentQuantity - 1
      });
    }
  };

  useEffect(() => {
    let sum = 0;
    if (Array.isArray(userCartState)) {
      userCartState.forEach(item => {
        sum += Number(item.quantity) * item.price;
      });
    }
    setTotalAmount(sum);
  }, [userCartState]);

  return (
    <>
      <Meta title="Cart" />
      <BreadCrumb title="Cart" />
      <Container class1="cart-wrapper">
        <div className='section-11'>
          <div className="cart-header d-flex align-items-center justify-content-between mb-10">
            <h3 className="cart-col-1">PRODUCT</h3>
            <h3 className="cart-col-2">PRICE</h3>
            <h3 className="cart-col-3">COUNT</h3>
            <h3 className="cart-col-4">TOTAL</h3>
          </div>
          {Array.isArray(userCartState) && userCartState?.map((item, index) => (
            <div key={index} className="cart-data d-flex align-items-center justify-content-between">
              <div className="cart-col-1 d-flex align-items-center gap-10">
                <div className="cart-dis">
                  <img src={item?.productId?.images[0]?.url} alt="" className="w-100" />
                </div>
                <div className="cart-des">
                  <p>{item?.productId?.title}</p>
                  <p className="d-flex gap-5 align-items-center">
                    Color:
                    <ul className="colors">
                      <li style={{ backgroundColor: item?.color?.title }}></li>
                    </ul>
                  </p>
                  <p className='d-flex gap-5 align-items-center'>
                    Size:
                    <span>{item?.size}</span>
                  </p>
                </div>
              </div>
              <div className="cart-col-2">
                <p className="price-style"><strong>{item?.price}</strong>/=</p>
              </div>
              <div className="cart-col-3 d-flex align-items-center gap-10">
                
                  <button
                    className="quantity-btn"
                    onClick={() => decrementQuantity(item?._id, item?.quantity)}
                  >
                    -
                  </button>
                  <span className="quantity-display">
                    {productUpdateDetail?.cartItemId === item?._id ? productUpdateDetail.quantity : item?.quantity}
                  </span>
                  <button
                    className="quantity-btn"
                    onClick={() => incrementQuantity(item?._id, item?.quantity)}
                  >
                    +
                  </button>
                
                <div className='butt-ddele'>
                  <RiDeleteBin6Line
                    onClick={() => deleteACartProduct(item?._id)}
                    className="dele-cart text-danger"
                  />
                </div>
              </div>
              <div className="cart-col-4">
                <p className="price-style"><strong>{item?.price * item?.quantity}</strong>/=</p>
              </div>
            </div>
          ))}
          <div className="pad-cart d-flex justify-content-between align-items-center">
            <Link to="/product" className="button">Continue Shopping</Link>
            {totalAmount > 0 && (
              <div className="d-flex flex-column align-items-end">
                <h3 className="fo-cart mb-10">SubTotal: {totalAmount}/=</h3>
                <p className='cart-foo'>Taxes and Shipping (Delivery) calculated at checkout</p>
                <Link to="/checkout" className="button check-size mt-10">Checkout</Link>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Cart;