import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta'
import Container from '../components/Container';

const ShippingPolicy = () => {
  return (
    <>
    <Meta title='Shipping Policy'/>
    <BreadCrumb title='Shipping Policy'/>
    <Container class1='policy-wrapper'>
      <div className='Policy'>
          
      </div>
    </Container>
   
    </>
  )
}

export default ShippingPolicy
