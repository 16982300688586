import axios from "axios";
import { base_url, config } from "../../../utils/axiosConfig";

const register = async (userData) => {
  try {
    const response = await axios.post(`${base_url}user/register`, userData);
    if (response.data) {
      localStorage.setItem('customer', JSON.stringify(response.data));
      return response.data;
    }
  } catch (error) {
    console.error("Error during registration:", error);
    throw error;
  }
}

const login = async (userData) => {
  try {
    const response = await axios.post(`${base_url}user/login`, userData);
    if (response.data) {
      localStorage.setItem('customer', JSON.stringify(response.data));
      return response.data;
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
}

const getUserWishlist = async ()=> {
  const response = await axios.get(`${base_url}user/wishlist`, config);
  if (response.data) {
    return response.data;
  }
};


const addToCart = async (cartData) => {
  try {
    const response = await axios.post(`${base_url}user/cart`, cartData, config);
    return response.data;
  } catch (error) {
    // Ensure 'error' is used correctly here
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

const getCart = async () =>{
  const response = await axios.get(`${base_url}user/cart`, config);
  if (response.data) {
    return response.data;
  }
};

const removeProductFromCart = async (cartItemId) => {
  const response = await axios.delete(`${base_url}user/delete-product-cart/${cartItemId}`, config);
  if (response.data) {
    return response.data;
  }
}

const updateProductFromCart = async (cartDetail) => {
  const response = await axios.delete(`${base_url}user/update-product-cart/${cartDetail.cartItemId}/${cartDetail.quantity}`, config);
  if (response.data) {
    return response.data;
  }
}

const getUserOrders = async () => {
  const response = await axios.get(`${base_url}user/getmyorders`, config);
  if(response.data) {
    return response.data;

  }
}

const updateUser = async (data) => {
  const response = await axios.put(`${base_url}user/edit-user`, data, config);
  console.log(data);
  if(response.data) {
    return response.data;

  }
}

const forgotPassToken = async (data)=> {
  const response = await axios.post(`${base_url}user/forgot-password-token`, data );
  if(response.data) {
    return response.data;

  }
}

const resetPass = async (data)=> {
  const response = await axios.put(`${base_url}user/reset-password/${data.token}`, {password: data?.password} );
  if(response.data) {
    return response.data;

  }
}

const createOrder = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${base_url}user/cart/create-order`, data, config);
    
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error('Error creating order:', error);
    throw error; // Optional: rethrow the error to handle it in the calling function
  }
};

const emptyCart = async (data)=> {
  const response = await axios.delete(`${base_url}user/empty-cart`, config );
  if(response.data) {
    return response.data;

  }
}



export const authService = {
  register,
  login,
  getUserWishlist,
  addToCart,
  getCart,
  removeProductFromCart,
  updateProductFromCart,
  getUserOrders,
  updateUser,
  forgotPassToken,
  resetPass,
  emptyCart,
  createOrder,
  
}