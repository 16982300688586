import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ title, description, images, date, id }) => {
  const imageUrl = Array.isArray(images) ? images[0]?.url : images;

  return (
    <div className='blog-card'>
      <div className='card-image'>
        {/* Display the image */}
        <img src={imageUrl || 'default_image_url.jpg'} alt={title || 'Blog Image'} className='image-c' />
      </div>
      <div className='blog-content'>
        <p className='date'>{date || 'Date'}</p>
        <h5 className='title'>{title || 'Title'}</h5>
        <p className='description' dangerouslySetInnerHTML={{ __html: description || 'Description' }}></p>
        
        <Link to={`/product`} className='button'>Shop Now</Link>
      </div>
    </div>
  );
};

export default BlogCard;