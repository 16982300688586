import axios from "axios";
import { base_url } from "../../../utils/axiosConfig";

const createOrder = async (orderData) => {
  try {
    const response = await axios.post(`${base_url}print`, orderData);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
};

const getOrder = async (id) => {
  try {
    const response = await axios.get(`${base_url}print/${id}`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching order:", error);
    throw error;
  }
};

const getOrders = async () => {
  try {
    const response = await axios.get(`${base_url}print`);
    return response.data;
  } catch (error) {
    console.error("Error fetching orders:", error.response?.data || error.message);
    throw error;
  }
};

export const printService = {
  createOrder,
  getOrder,
  getOrders,
};