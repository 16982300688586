import React, { useState } from 'react'
import BreadCrumb from '../components/BreadCrumb'
import Container from '../components/Container'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../app/features/user/userSlice';
import { FiEdit } from "react-icons/fi";


const profileSchema = yup.object({
  firstname: yup.string().required('First Name is required'),
  lastname: yup.string().required('Last Name is required'),
  email: yup.string().email('Email should be valid').required('Email Address is required'),
  mobile: yup.string().required('Mobile No. is required'),
});

const Profile = () => {
  const dispatch = useDispatch();
  const userState = useSelector(state => state.auth.user);
  const [edit, setEdit] = useState(true);

  const formik = useFormik({
    initialValues: {
      firstname: userState?.firstname || '',
      lastname: userState?.lastname || '',
      email: userState?.email || '',
      mobile: userState?.mobile || '',
    },
    validationSchema: profileSchema,
    onSubmit: values => {
      dispatch(updateProfile(values));
      setEdit(true);
    },
  });
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <BreadCrumb title='My Profile'/>
      <Container class1='wel-back'>
      <h2>Account</h2>

        <h4>Welcome {userState?.firstname}</h4>
        <p className='mt-10'>{userState?.email}</p>
      </Container>
      <Container class1='sec-11'>
        <div>
          <div className='d-flex justify-content-between align-items-center border-bottom'>
            <h3 className='mt-10 mb-10'>Update Profile</h3>
            <FiEdit className='fs-5 curso' onClick={()=> setEdit(false)}/>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3 mt-10">
              <label htmlFor="example1" className="form-lab">First Name</label>
              <input 
                type="text" 
                name='firstname'
                disabled={edit} 
                className="form-p" 
                id="example1" 
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.firstname && formik.errors.firstname && <div className='error'>{formik.errors.firstname}</div>}
            </div>
            <div className="mb-3 mt-10">
              <label htmlFor="example3" className="form-lab">Last Name</label>
              <input 
                type="text" 
                name='lastname'
                disabled={edit} 
                className="form-p" 
                id="example3"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.lastname && formik.errors.lastname && <div className='error'>{formik.errors.lastname}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-lab">Email address</label>
              <input 
                type="email" 
                name='email'
                disabled={edit} 
                className="form-p" 
                id="exampleInputEmail1" 
                aria-describedby="emailHelp"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && <div className='error'>{formik.errors.email}</div>}
            </div>
            <div className="mb-3 mt-10">
              <label htmlFor="example2" className="form-lab">Mobile Number</label>
              <input 
                type="number" 
                name='mobile'
                disabled={edit} 
                className="form-p" 
                id="example2"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.mobile && formik.errors.mobile && <div className='error'>{formik.errors.mobile}</div>}
            </div>
            
              {
                edit === false && <button type="submit" className="button mt-10">Save</button>
              }
          </form>
          <div>
          <button onClick={handleLogout} className='logoutpro-b' type='button'>LOGOUT</button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Profile