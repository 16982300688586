import React, { useEffect, useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import ReactStars from 'react-rating-stars-component';
import ReactImageZoom from 'react-image-zoom';
import { LuHeart } from "react-icons/lu";
import Container from '../components/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addRating, addToWishlists, getAllProducts, getAProduct } from '../app/features/products/productSlice';
import Color from '../components/Color';
import { toast } from 'react-toastify';
import { addProdToCart, getUserCart } from '../app/features/user/userSlice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Carousel from 'react-multi-carousel';
import Dropzone from 'react-dropzone';
import { deleteAFile, uploadAFile } from '../app/features/upload/uploadSlice';
import { IoMdClose } from "react-icons/io";

const SingleProduct = () => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 6, slidesToSlide: 2 },
    desktop: { breakpoint: { max: 1024, min: 800 }, items: 3 },
    tablet: { breakpoint: { max: 800, min: 464 }, items: 4 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 3 },
  };

  const responsive2 = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 4, slidesToSlide: 2 },
    desktop: { breakpoint: { max: 1024, min: 800 }, items: 1 },
    tablet: { breakpoint: { max: 800, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const [mainImageUrl, setMainImageUrl] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [star, setStar] = useState(null);
  const [comment, setComment] = useState('');
  const [popularProduct, setPopularProduct] = useState([]);
  const [starSize, setStarSize] = useState(getStarSize());
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getProductId = location.pathname.split('/')[2];
  
  const productState = useSelector(state => state.product.singleProduct);
  const productsState = useSelector(state => state.product.filteredProducts);
  const cartState = useSelector(state => state.auth.cartProduct);
 

  function getStarSize() {
    const width = window.innerWidth;
    return width <= 360 ? 18 : (width <= 768 ? 18 : 24);
  }

  useEffect(() => {
    const handleResize = () => setStarSize(getStarSize());
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(getAProduct(getProductId));
    dispatch(getUserCart());
    dispatch(getAllProducts());
  }, [dispatch, getProductId]);

  useEffect(() => {
    if (cartState?.some(item => item.productId._id === getProductId)) {
      setAlreadyAdded(true);
    }
  }, [cartState, getProductId]);

  useEffect(() => {
    if (productState?.images?.length > 0) {
      setMainImageUrl(productState.images[0].url);
    }
  }, [productState]);

  useEffect(() => {
    const popularProducts = productsState.filter(element => element.tags === 'popular');
    setPopularProduct(popularProducts);
  }, [productsState]);

  const addToWish = (id) => {

    dispatch(addToWishlists(id))
      .then(() => toast.success('Product added to wishlist!'))
      .catch((error) => toast.error(`Failed to add product to wishlist: ${error.message}`));
  };

  const uploadCart = () => {
    if (productState?.category === 'Clothes' && !size) {
      toast.error('Please Choose Size');
      return;
    }
    if (!color) {
      toast.error('Please Choose Color');
      return;
    }
    dispatch(addProdToCart({
      productId: productState?._id,
      quantity,
      color,
      size,
      uploadedFiles,
      price: productState?.price
    }));
    navigate('/cart');
  };

  const addRatingToProduct = () => {
    if (!star || !comment) {
      toast.error('Please provide a star rating and comment.');
      return;
    }
    dispatch(addRating({ star, comment, prodId: getProductId }))
      .then(() => {
        setTimeout(() => dispatch(getAProduct(getProductId)), 100);
        setStar(null);
        setComment('');
      });
  };

  const handleImageClick = (url) => setMainImageUrl(url);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
    setSize(event.target.value);
  };

  const props = {
    width: 500,
    height: 500,
    zoomWidth: 500,
    img: mainImageUrl || 'https://via.placeholder.com/500',
  };

 
  const handleDeleteFile = async (public_id) => {
    try {
      const result = await dispatch(deleteAFile(public_id)).unwrap();
      
      if (result) {
        // Update the uploadedFiles state to remove the deleted file
        setUploadedFiles((files) => files.filter((file) => file.public_id !== public_id));
        toast.success('File deleted successfully');
      } else {
        throw new Error('Failed to delete file');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      console.error('Error during file deletion:', errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleFileDrop = (acceptedFiles) => {
    setUploading(true);
    dispatch(uploadAFile(acceptedFiles))
      .unwrap()
      .then((uploadedFiles) => { // Adjusted the variable name
        setUploading(false);
        if (Array.isArray(uploadedFiles)) {
          setUploadedFiles(prevFiles => [...prevFiles, ...uploadedFiles]); // Add uploaded files to state
          toast.success('File uploaded successfully');
        } else {
          console.error('Unexpected response format:', uploadedFiles);
          toast.error('Unexpected response format from file upload');
        }
      })
      .catch((error) => {
        setUploading(false);
        toast.error('Failed to upload File');
        console.error('Error uploading File:', error);
      });
  };

  return (
    <>
      <Meta title={productState?.title || 'Product'} />
      <BreadCrumb title={productState?.title} />

      <Container class1='main-product-wrapper section-11'>
        <div className='container-xxl'>
          <div className='row bg-white mb-row'>
            <div className='col-6'>
              <div className='main-pic sing-none'>
                <ReactImageZoom {...props} />
              </div>
              {productState?.images ? (
                <Carousel 
                  showDots={true} 
                  itemClass='mb-20' 
                  responsive={responsive2}
                  className='other-product-images d-flex justify-content-between flex-wrap'>
                  {productState.images.map((item, index) => (
                    <div key={index}>
                      <img 
                        onClick={() => handleImageClick(item.url)} 
                        src={item.url || '/fallback-image.jpg'} 
                        className='w-100' 
                        alt={`Product ${index + 1}`} 
                      />
                    </div>
                  ))}
                </Carousel>
              ) : (
                <p>Loading images...</p>
              )}
            </div>
            <div className='col-6'>
              <div className='main-product-details'>
                <div className='border-bottom'>
                  <strong>{productState?.title}</strong>
                </div>
                <div className='border-bottom'>
                  <p className='price-style font-s mb-10'>UGX <strong>{productState?.price}</strong></p>
                  <div className='d-flex align-items-center gap-10 mb-10'>
                    <ReactStars
                      count={5}
                      size={starSize}
                      value={productState?.totalrating}
                      edit={false}
                      activeColor="#ffd700"
                    />
                    <p className='para-14'>({productState?.ratings?.length || 0} Reviews)</p>
                  </div>
                  <a className='para-14' href='#review'>Write a Review</a>
                </div>
                <div className='border-bottom d-flex gap-10 mt-10'>
                  <strong>Brand:</strong>
                  <p>{productState?.brand}</p>
                </div>
                <div className='border-bottom d-flex gap-10 mt-10'>
                  <strong>Category:</strong>
                  <p>
                    {Array.isArray(productState?.category) && productState.category.map((cat, index) => (
                      <span key={index}>
                        {cat.title} {index < productState.category.length - 1 && ', '}
                      </span>
                    ))}
                  </p>
                </div>
                <div className='border-bottom d-flex gap-10 mt-10'>
                  <strong>Tags:</strong>
                  <p>{productState?.tags}</p>
                </div>
                {productState?.category === 'Clothes' && (
                  <div className='border-bottom mt-10 mb-10'>
                    <h3 className='font-tt'>Size</h3>
                    <select className='sizes number-pro' id="size" value={selectedSize} onChange={handleSizeChange}>
                      <option value="" disabled>Select a size</option>
                      {productState?.size?.map(size => (
                        <option key={size._id} value={size.title}>
                          {size.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className='border-bottom'>
                  <h3 className='font-tt'>Color</h3>
                  <Color setColor={setColor} colorData={productState?.color} />
                </div>
                <div className='border-bottom mt-10'>
                  {!alreadyAdded && (
                    <>
                      <h3 className='font-tt mb-10'>Quantity</h3>
                      <input
                        className='sizes number-pro'
                        type="number"
                        min={1}
                        onChange={(e) => setQuantity(e.target.value)}
                        value={quantity}
                      />
                    </>
                  )}
                </div>
                {(productState?.category === 'Printable' || productState?.category === 'Clothes') && (
                  <div className='border-bottom mt-10'>
                    <h3 className='font-tt'>Upload For Printing</h3>
                    <div className='upload border-1 bg-white text-center p-5'>
                      <Dropzone onDrop={handleFileDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>{uploading ? 'Uploading...' : "Drag 'n' drop some files here, or click to select files"}</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <p className='font-13 mb-10'>The maximum file size is 30mb (Pdf, doc, eps, svg)</p>

                    <div className='showfiles'>
                      {uploadedFiles.map((file, index) => (
                        <div className='pos-relative' key={index}>
                          <p className='mbt'>{file.fileName}</p>
                          <button
                            type='button'
                            onClick={() => handleDeleteFile(file.public_id)}
                            className='btn-close'
                            style={{ top: '10px', right: '0px' }}
                          ><IoMdClose className='font-iccc' /></button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <button
                    onClick={() => alreadyAdded ? navigate('/cart') : uploadCart()}
                    className={`addcart-but mt-10 mb-10 ${alreadyAdded ? 'btn-disabled' : ''}`}
                  >
                    {alreadyAdded ? 'Go To Cart' : 'Add to Cart'}
                  </button>
                <div className='mt-10 d-flex align-items-center border-bottom'>
                  <div onClick={() => addToWish(productState?._id)} className='addwish-ic d-flex align-items-center gap-10 icon-link'>
                    <LuHeart className='fs-5 add-wi' /> Add to Wishlist
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container class1='description-wrapper section-11'>
        <div className='bg-white p-30 descrip-mob'>
          <p dangerouslySetInnerHTML={{ __html: productState?.description }}></p>
        </div>
      </Container>

      <Container class1='reviews-wrapper section-11'>
        <h4 id='review'>Reviews</h4>
        <div className='row'>
          <div className='col-rev w-100 bg-white'>
            <div className='review-head'>
              <div>
                <h4>Customer Reviews</h4>
                <div className='write-r d-flex gap-10 align-items-center'>
                  <ReactStars
                    count={5}
                    size={starSize}
                    value={productState?.totalrating || 0}
                    edit={false}
                    activeColor="#ffd700"
                  />
                  <p className='mb-0'>Based on {productState?.ratings?.length || 0} Reviews</p>
                </div>
              </div>
              {productState && (
                <div className='mob-wr'>
                  <a className='write-r' href='#review'>Write a Review</a>
                </div>
              )}
            </div>
            <div className='review-form'>
              <h4>Write A Review</h4>
              <div>
                <ReactStars
                  count={5}
                  size={starSize}
                  value={star}
                  edit={true}
                  activeColor="#ffd700"
                  onChange={setStar}
                />
              </div>
              <div>
                <textarea
                  className='w-100 text-b'
                  cols='30'
                  rows='5'
                  placeholder='Comments'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
              <div className='d-flex justify-content-end'>
                <button onClick={addRatingToProduct} className='button-cont' type='button'>Submit Review</button>
              </div>
            </div>
            <div className='reviews'>
              {productState?.ratings?.map((item, index) => (
                <div key={index} className='review'>
                  <div className='d-flex gap-10 align-items-center'>
                    <ReactStars
                      count={5}
                      size={starSize}
                      value={item?.star}
                      edit={false}
                      activeColor="#ffd700"
                    />
                  </div>
                  <p className='mt-10'>{item?.comment}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>

      <Container class1='banner-section-3 mbbb'>
        <h3 className='section-heading'>Our Popular Products</h3>
        <Carousel 
          showDots={true} 
          itemClass='mb-20' 
          className='blog' 
          responsive={responsive}
        >
          {popularProduct.length > 0 ? (
            popularProduct.map((item, index) => (
              <div key={index} className='col-4'>
                <div onClick={() => navigate('/product/' + item?._id)} className='product-card'>
                  <div className='product-image'>
                    <img src={item?.images[0]?.url} alt={`Product ${item?.title}`} className='prod-img' />
                  </div>
                  <div className='product-details'>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <ReactStars 
                      count={5}
                      size={starSize}
                      value={Number(item?.totalrating)}
                      edit={false}
                      activeColor='#ffdf00'
                    />
                  </div>
                    <p>{item?.brand}</p>
                    <h6 className='brand'>{item?.title}</h6>
                    <p className='price-style'>UGX <strong>{item?.price}</strong></p>
                    
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No Popular products found</p>
          )}
        </Carousel>
      </Container>
    </>
  );
};

export default SingleProduct;