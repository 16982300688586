import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta'
import Container from '../components/Container';
import Custominput from '../components/Custominput';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../app/features/user/userSlice';

const passwordSchema = yup.object({
  password: yup.string().required('Password is Required'),
  

});


const Resetpassword = () => {
  const location = useLocation();
  const getToken = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: passwordSchema,  // Added validation schema
    onSubmit: values => {
      dispatch(resetPassword({token: getToken, password: values.password}));
          navigate('/login');
       
    },
  });
  return (
    <>
      <Meta title='Reset Password'/>
      <BreadCrumb title='Reset Password'/>
      <Container class1='login-wrapper banner-section-2'>
        <div className='col-27'>
          <div className='auth-card'>
            <h3>Reset Password</h3>
            
                <form onSubmit={formik.handleSubmit} action='' className='formms-log'>
                  <Custominput type='password' name='password' placeholder='Password'
                  onChange = {formik.handleChange('password')}
                  onBlur = {formik.handleBlur('password')}
                  value = {formik.values.password}
                  />
                  <div className='error'>
                    {formik.touched.password && formik.errors.password}
                  </div>

                 
              
                 

                  <div>
                  
                    <div className='d-flex justify-content-center align-items-center gap-15'>
                      <button className='button-cont' type='submit'>Ok
                      </button>
                      

                    </div>
                  </div>
                  
                  
                </form>
          
          </div>
        </div>
      </Container>
    
    </>
  )
}

export default Resetpassword