import axios from "axios";
import { base_url } from "../../../utils/axiosConfig";




const getPosts = async() => {
  try {
    const response = await axios.get(`${base_url}post/`);
    console.log('API Response:', response.data); // Check if data is being received
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error); // Log any errors
    throw error;
  }
};








export const postService = {
  getPosts,
  
}