import React, { useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';
import a0 from '../images/banner/a0-01.png';
import Dropzone from 'react-dropzone';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { deleteAFile, uploadAFile } from '../app/features/upload/uploadSlice';
import { IoMdClose } from "react-icons/io";
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { createAPrintOrder } from '../app/features/PrintOrder/printSlice';

// Validation schema
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  mobile: yup.string().required('Mobile number is required'),
  quantity: yup.number().required('Number of copies is required').min(1, 'Quantity must be at least 1'),
  instruction: yup.string().required('Instructions are required'),
});

const PrintOrder = () => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  //const printState = useSelector((state) => state.print.printOrders);


  /*useEffect(() => {
    dispatch(getAllPrintOrders());
  }, [dispatch]);*/

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      quantity: 1,
      instruction: '',
      uploadedFiles: [],
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(createAPrintOrder({
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        quantity: values.quantity,
        instruction: values.instruction,
        uploadedFiles: values.uploadedFiles,
      }))
      resetForm();
    },
  });

  const handleDeleteFile = async (public_id) => {
    try {
      const result = await dispatch(deleteAFile(public_id)).unwrap();
      if (result) {
        const updatedFiles = formik.values.uploadedFiles.filter((file) => file.public_id !== public_id);
        formik.setFieldValue('uploadedFiles', updatedFiles);
        toast.success('File deleted successfully');
      } else {
        throw new Error('Failed to delete file');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      console.error('Error during file deletion:', errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleFileDrop = (acceptedFiles) => {
    setUploading(true);
    dispatch(uploadAFile(acceptedFiles))
      .unwrap()
      .then((uploadedFiles) => {
        setUploading(false);
        if (Array.isArray(uploadedFiles)) {
          const newFiles = [...formik.values.uploadedFiles, ...uploadedFiles];
          formik.setFieldValue('uploadedFiles', newFiles);
          toast.success('File uploaded successfully');
        } else {
          console.error('Unexpected response format:', uploadedFiles);
          toast.error('Unexpected response format from file upload');
        }
      })
      .catch((error) => {
        setUploading(false);
        toast.error('Failed to upload File');
        console.error('Error uploading File:', error);
      });
  };

  return (
    <>
      <Meta title='Order Form' />
      <BreadCrumb title='Order Form' />
      <Container class1='print-wrapper'>
        <div className='print-bar'>
          <div className='bar-fo'></div>
          <div className='bar-form'>
            <p>PRINT ORDER FORM</p>
          </div>
        </div>

        <div className='form-print'>
          <form onSubmit={formik.handleSubmit} className='formms'>
            <div className='adv-form'>
              <div className='upper-f'>
                <div>
                  <input
                    type='text'
                    className='form-p'
                    placeholder='Name'
                    name='name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <div className='error'>{formik.touched.name && formik.errors.name}</div>
                </div>
                <div>
                  <input
                    type='email'
                    className='form-p'
                    placeholder='Email'
                    name='email'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <div className='error'>{formik.touched.email && formik.errors.email}</div>
                </div>
                <div>
                  <input
                    type='tel'
                    className='form-p'
                    placeholder='Mobile Number'
                    name='mobile'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                  />
                  <div className='error'>{formik.touched.mobile && formik.errors.mobile}</div>
                </div>
                <div>
                  <p>Number of Copies</p>
                  <input
                    className='form-p'
                    type='number'
                    min={1}
                    name='quantity'
                    onChange={formik.handleChange}
                    value={formik.values.quantity}
                  />
                </div>
              </div>

              <div className='advert-p'>
                <div className='print-descrip'>
                  <h3 className='pap-size'>PAPER SIZES INCLUDE;</h3>
                </div>
                <div>
                  <img src={a0} alt='' className='a0-img' />
                </div>
              </div>
            </div>
            <div>
              <div className='border-bottom mt-10'>
                <h3 className='font-tt'>Upload For Printing</h3>
                <div className='upload border-1 bg-white text-center p-5'>
                  <Dropzone onDrop={handleFileDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>{uploading ? 'Uploading...' : "Drag 'n' drop some files here, or click to select files"}</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <p className='font-13 mb-10'>The maximum file size is 30mb (Pdf, doc, eps, svg)</p>

                <div className='showfiles'>
                  {formik.values.uploadedFiles.map((file, index) => (
                    <div className='pos-relative' key={index}>
                      <p className='mbt'>{file.fileName}</p>
                      <button
                        type='button'
                        onClick={() => handleDeleteFile(file.public_id)}
                        className='btn-close'
                        style={{ top: '10px', right: '0px' }}
                      ><IoMdClose className='font-iccc' /></button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <p>Instructions</p>
              <textarea
                className='text-b w-100' 
                cols='30' 
                rows='5'
                name='instruction'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.instruction}
              ></textarea>
              <div className='error'>{formik.touched.instruction && formik.errors.instruction}</div>
            </div>
            <button type='submit' className='button-submit'>Submit</button>
          </form>
        </div>
      </Container>
    </>
  );
};

export default PrintOrder;