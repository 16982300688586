import React from 'react'
import Meta from '../components/Meta'
import { Link } from 'react-router-dom';
import Container from '../components/Container';
import Custominput from '../components/Custominput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { forgotPasswordTok } from '../app/features/user/userSlice';

const emailSchema = yup.object({
  email: yup.string().email('Email should be valid').required('Email Address is required'),

});

const Forgotpassword = () => {

  //const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailSchema,  // Added validation schema
    onSubmit: values => {
      dispatch(forgotPasswordTok(values));
         
       
    },
  });
  return (
    <>
    <Meta title='Forgot Password'/>
   
    
    <Container class1='login-wrapper'>
      <div className='col-27'>
          <div className='auth-card'>
            <h3>Reset Your Password</h3>
            <p className='reset-p'>We will send you an email to reset your password</p>
            
                <form action='' onSubmit={formik.handleSubmit} className='formms-log'>
                  <Custominput 
                    type='email' 
                    name='email' 
                    placeholder='Email'
                    onChange = {formik.handleChange('email')}
                    onBlur = {formik.handleBlur('email')}
                    value = {formik.values.email}
                    />
                    <div className='error text-center'>
                      {formik.touched.email && formik.errors.email}
                    </div>
                  
                

                  <div>
                    
                    <div className='d-flex justify-content-center align-items-center gap-15 flex-column'>
                      <button className='butt-sign' type='submit'>Submit</button>
                      <Link className='marg' to='/login'>Cancel</Link>

                    </div>
                  </div>
                  
                  
                </form>
          
          </div>
        </div>
    </Container>
   
    </>
  )
}

export default Forgotpassword