import React from 'react'
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta'
import Container from '../components/Container';
import { TfiHelpAlt } from "react-icons/tfi";


const Help = () => {
  return (
    <>
      <Meta title='Help'/>
      <BreadCrumb title='Help'/>
      <Container class1='policy-wrapper'>
      <div className='help-b'>
        <div>
        <p className='font-14'>Help Center</p>
              <br/>
              <h1>Welcome to the Kupto Help Center</h1>
        </div>
              
              <div>
              <TfiHelpAlt className='help-s'/>
              </div>
            </div>
        <div className='Policy'>
          <div className='t-policy'>
           
          <p>We're here to assist you with any questions or concerns you may have about our products and services.</p>
            <br/>
            <p>1. <strong>Frequently Asked Questions (FAQ)</strong> </p>
            <br/>

            
            <p><strong>Ordering</strong></p>
            <br/>
            <p>Q: <strong>How do I place an order?</strong></p>
            <br/>
            <p>A: To place an order, browse our products and click on them or "view" for the items you wish to purchase. Once you're ready, go to your cart and click "Checkout" to complete your order.</p>
            <br/>
            

            <p>Q: <strong>Can I cancel or modify my order?</strong></p>
            <br/>

            <p>A: You can cancel or modify your order within 24 hours of placing it. Please contact our support team immediately to make changes.</p>
            <br/>

            <p><strong>Shipping</strong></p>
            <br/>

            <p>Q: <strong>What are the shipping options and costs?</strong></p>
            <br/>

            <p>A: We offer standard and express shipping options. Shipping costs vary based on your location and the shipping method selected. You can view the shipping costs at checkout.</p>
            <br/>
            
            <p>Q: <strong>How can I track my order?</strong></p>
            <br/>
         
            <p>A: Once your order has been shipped, you will receive an email with a tracking number and a link to track your package.</p>
            <br/>

            <p><strong>Returns and Refunds</strong></p>
            <br/>

            <p>Q: <strong>What is your return policy?</strong></p>
            <br/>

            <p>A: We accept returns within 5 days of delivery. Items must be in their original condition and packaging. Please contact us for return instructions.</p>
            <br/>

            <p>Q: <strong>How long does it take to process a refund?</strong></p>
            <br/>

            <p>A: Refunds are processed within 4 days of receiving the returned item. You will receive an email confirmation once the refund has been issued.</p>
            <br/>
            
            <p>2. <strong>Contact Us</strong> </p>
            <br/>
            <p>If you need further assistance, please contact our customer support team:</p>
            <br/>
            <p>Email: kupto@gmail.com</p>
            <br/>

            <p>Phone: [+256 704116349] (available Monday to Friday, 9 AM - 5 PM)</p>
            <br/>

            <p>Live Chat: Available on our website from Monday to Friday, 9 AM - 5 PM</p>
            <br/>

            <p>3. <strong>Account Management</strong> </p>
            <br/>
            <p><strong>Creating an Account</strong></p>
            <br/>

            <p>To create an account, click on the "Sign Up" button on our homepage and fill in the required information. You will receive a confirmation email to verify your account.</p>
            <br/>

            <p><strong>Managing Your Account</strong></p>
            <br/>

            <p>Log in to your account to view your order history, update your personal information, and manage your shipping addresses.</p>
            <br/>

            <p><strong>Resetting Your Password</strong></p>
            <br/>

            <p>If you forget your password, click on the "Forgot Password" link on the login page. Enter your email address, and we will send you instructions to reset your password.</p>
            <br/>

            <p>4. <strong>Payment Information</strong> </p>
            <br/>

            <p><strong>Accepted Payment Methods</strong></p>
            <br/>
   
            <p>We accept the following payment methods:</p>
            <br/>
            <p>Credit/Debit Cards (Visa, MasterCard)</p>
            <p>PayPal</p>
            <p>Mobile Money</p>
            <p>Payment Security</p>
            <p>We use secure payment gateways to ensure your payment information is protected. Your payment details are encrypted and processed securely.</p>
            <br/>

            <p>5. <strong>Product Information</strong> </p>
            <br/>

            <p><strong>Product Descriptions</strong></p>
            <br/>

            <p>We strive to provide accurate and detailed product descriptions. If you have any questions about a product, please contact us for more information.</p>
            <br/>

            <p><strong>Product Availability</strong></p>
            <br/>

            <p>All products are subject to availability. In the event a product is out of stock, we will notify you and provide an estimated restock date.</p>
            <br/>

            <p>6. <strong>Technical Support</strong> </p>
            <br/>

            <p><strong>Website Issues</strong></p>
            <br/>

            <p>If you experience any issues with our website, please clear your browser cache and cookies or try using a different browser. If the problem persists, contact our technical support team at kupto@gmail.com.</p>
            <br/>

            <p><strong>Mobile App Support</strong></p>
            <br/>

            <p>For issues with our mobile app, please ensure you have the latest version installed. If you continue to experience problems, contact our support team with details about the issue and the device you are using.</p>
            <br/>

            <p>7. <strong>Feedback and Complaints</strong> </p>
            <br/>
            <p><strong>Providing Feedback</strong></p>
            <br/>

            <p>We value your feedback and use it to improve our services. Please send your feedback to kupto@gmail.com.</p>
            <br/>

            <p><strong>Filing a Complaint</strong></p>
            <br/>

            <p>If you have a complaint, please contact our customer support team. We will do our best to resolve the issue promptly.</p>

   
            
          </div>










            
        </div>
      </Container>
     
    </>
  )
}

export default Help;
