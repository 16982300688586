import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { FaInternetExplorer } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { FaHeartCircleCheck } from "react-icons/fa6";
import { MdContactPhone } from "react-icons/md";





const NavbarMobile = () => {
  const authState = useSelector(state => state?.auth);

  return (
    <div className="navbar-mobile">
      <Link to='/' className='navbar-mobi'>
        <FaHome className='fs-nav'/>
        <p>Home</p>
      </Link>
      <Link to='/product' className='navbar-mobi'>
        <FaInternetExplorer className='fs-nav2'/>
        <p>Explore</p>
      </Link>
      <Link to='/Contact' className='navbar-mobi'>
        <MdContactPhone className='fs-nav3'/>
        <p>Contact</p></Link>

        <Link to='/Whishlist' className='navbar-mobi'>
        <FaHeartCircleCheck className='fs-nav5'/>
        <p>Favourite</p></Link>

      <Link to={authState?.user ? '/my-profile' : '/login'} 
                   className='navbar-mobi'>
        <IoPerson className='fs-nav4'/>
        <p>Profile</p>
      </Link>

      
      

    </div>
  );
};

export default NavbarMobile;

