import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uploadService from "./uploadService"; // Ensure the correct import path

export const uploadAFile = createAsyncThunk(
  'upload/files',
  async (data, thunkAPI) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < data.length; i++) {
        formData.append('files', data[i]);
      }
      return await uploadService.uploadFile(formData);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.message,
        response: error.response?.data || null,
      });
    }
  }
);

export const deleteAFile = createAsyncThunk(
  'delete/file',
  async (id, thunkAPI) => {
    try {
      await uploadService.deleteFile(id);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.message,
        response: error.response?.data || null,
      });
    }
  }
);

const initialState = {
  files: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const uploadSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadAFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadAFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.files = action.payload;
      })
      .addCase(uploadAFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message || 'Upload failed';
      })
      .addCase(deleteAFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.files = state.files.filter(file => file.public_id !== action.payload);
      })
      .addCase(deleteAFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message || 'Delete failed';
      });
  },
});

export default uploadSlice.reducer;