import React, { useState } from 'react';

function Color(props) {
  const { colorData, setColor } = props;
  const [selectedColor, setSelectedColor] = useState(null); // State to store selected color ID

  const handleColorClick = (id) => {
    setColor(id); // Trigger the parent setColor function
    setSelectedColor(id); // Set the selected color ID
  };

  return (
    <>
      <ul className='colors mt-10'>
        {colorData &&
          colorData.map((item, index) => {
            return (
              <div
                className={`color-border ${
                  selectedColor === item?._id ? 'selected' : ''
                }`} // Add 'selected' class if this color is selected
                key={index}
              >
                <li
                  className='color-des'
                  onClick={() => handleColorClick(item?._id)}
                  style={{ backgroundColor: item?.title }}
                ></li>
              </div>
            );
          })}
      </ul>
    </>
  );
}

export default Color;