import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCategories } from './categoryService';


export const fetchCategories = createAsyncThunk('category/fetchCategories', async () => {
  const data = await getCategories();
  return data;
});



const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    isLoading: false,
    isError: false,
    message: '',
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message;
      });
  },
});

export const selectCategories = (state) => state.category.categories;
export default categorySlice.reducer;