import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';



const ProductCard = (props) => {
  const { grid, data } = props;
 
  let location = useLocation();
 // const dispatch = useDispatch();



  // Ensure data is an array
  const products = Array.isArray(data) ? data : [];

  const getStarSize = () => {
    const width = window.innerWidth;
    if (width <= 360) {
      return 18; // Adjust size for small screens (e.g., mobile devices)
    } else if (width <= 768) {
      return 18; // Size for larger screens (e.g., tablets and desktops)
    } else {
      return 22; // Default size for very large screens
    }
  };

   // Effect to handle star size changes on window resize
 useEffect(() => {
  const handleResize = () => {
    setStarSize(getStarSize());
  };

  window.addEventListener('resize', handleResize);
  handleResize(); // Set initial size

  return () => window.removeEventListener('resize', handleResize);
}, []);

const [starSize, setStarSize] = React.useState(getStarSize());

  return (
    <>
      {products.map((item, index) => (
        <div
          key={index}
          className={`${location.pathname === '/product' ? `gr-${grid}` : 'col-4'}`}
        >
          <Link to={`/product/${item?._id}`} className='product-card'>
            <div className='product-image'>
              <img src={item?.images[0]?.url} alt='' className='prod-img' />
            </div>
            <div className='product-details'>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <ReactStars 
                count={5}
                size={starSize}
                value={Number(item?.totalrating)}
                edit={false}
                activeColor='#ffdf00'
              />
            </div>
              <p>{item?.brand}</p>
              <h6 className='brand'>{item?.title}</h6>
              
              
              <p
                className={`descript ${grid === 12 ? 'd-block' : 'd-none'}`}
                dangerouslySetInnerHTML={{ __html: item?.description }}
              >
              </p>
              <p className='price-style'>UGX <strong>{item?.price}</strong></p>

              

              
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default ProductCard;